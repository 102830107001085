.searchBar {
    display: flex;
    align-items: center;
}

.searchBar input {
    padding: 0.8em 1em;
    padding-left: 3em;
    font-weight: 700;
}

.searchIconMagnifier {
    /* z-index: 5000; */
    position: relative;
    margin-right: -30px;
    /* position: absolute; */
}

.searchIconClear i {
    position: relative;
    margin-left: -35px;
    cursor: pointer;
}

.searchResult {
    text-align: left;
    margin-top: 1em;
    position: absolute;
    background-color: white;
    z-index: 1;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    width: 65%;
}

/* .searchFilterBy span:hover {
    color: #2AAADA;
    cursor: pointer;
} */

.searchResultOption {
    padding: 0.5em 1em;
    cursor: pointer;
}

.searchResultOption:hover {
    background: #CADCE275;
}
