.clientsPageContainer {
  margin-top: 4em;
}

.clientsTitle {
  font-weight: 700;
  color: black;
  padding-top: 100px;
  margin-left: 135px;
  text-align: left;
}

.clientsSubTitle {
  color: #1f81dc;
  margin: 67px 0px 10px 0px;
}

.clientsMiniSubTitle {
  color: #1f81dc;
  margin-bottom: 57px;
}

.clientsPage {
  padding: 20px;
}

.clientsLogoPage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 135px;
  margin-right: 135px;
  margin-bottom: 20px;
}

.clientsLogoContainerPage {
  max-width: 400px;
  width: 30%;
  height: auto;
  padding: 15px;
}

.clientsLogoContainerPage img {
  object-fit: contain;
  max-height: 120px;
  width: 100%;
  height: 100%;
}

.pastProjects {
  background-color: #fafafa;
  padding-top: 20px;
  padding-bottom: 20px;
}

.clientsProject {
  text-align: left;
  margin-left: 9.5em;
  margin-right: 20em;
}

.clientsProjectTitle {
  margin-top: 25px;
  font-weight: 700;
}

.clientsEachProject {
  margin-bottom: 3em;
}

.clientsProjectContent ul {
  list-style-type: none;
}

.clientsProjectContent li {
  margin-bottom: 10px;
}

@media only screen and (max-width: 820px) {
  .clientsTitle {
    margin-left: 1em;
  }

  .clientsLogoPage {
    margin: 0;
  }

  .clientsProject {
    text-align: left;
    margin-left: 2em;
    margin-right: 2em;
  }
}

@media only screen and (max-width: 500px) {
  .clientsTitle {
    padding-top: 120px;
    font-size: 1.875em;
  }

  .clientsSubTitle {
    font-size: 1.125em;
    margin: 39px 0px 25px 0px;
  }

  .clientsLogoContainerPage {
    width: 65%;
    height: auto;
    padding: 35px;
  }

  .clientsLogoContainerPage img {
    margin-bottom: 1em;
  }

  .clientsProject {
    margin-left: 1em;
    margin-right: 1em;
  }

  .clientsPageContainer {
    margin-top: 2em;
  }
}
