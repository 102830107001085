#admin {
  display: flex;
}

/* Admin Navbar */
.adminNavbar {
  z-index: 9999;
  font-size: 1.3em;
  text-align: left;
  width: 300px;
  height: 100%;
  background-color: #0d4c86;
  position: fixed;
  overflow-x: hidden;
}

.adminNavbar::-webkit-scrollbar {
  width: 12px;
}

.adminNavbar::-webkit-scrollbar-track {
  background: #0d4c86;
}

.adminNavbar::-webkit-scrollbar-thumb {
  background: #ffc635;
}

.adminNavbarTitle {
  margin: 3.8em 3.8em 2em;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*  */

.adminContainer {
  background-color: #fafafa;
  min-height: 1000px;
  height: 100%;
  width: 100%;
  margin-top: 2.7em;
  padding-top: 7em;
  padding-left: 300px;
  flex-direction: column;
  text-align: left;
}

/* Admin Search and Profile */
.adminTopNavigation {
  z-index: 888;
  position: fixed;
  width: 100%;
  background: white;
  padding-top: 3em;
  padding-bottom: 1em;
  padding-left: 350px;
  padding-right: 3.4em;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.03);
}

.adminTopNavigationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.adminSearch input {
  font-size: 0.8em;
  width: 45vw;
  border-radius: 25px;
  border: 1px solid black;
}

.profileIcon img {
  border-radius: 50%;
  padding: 0.3em;
  transition: 0.3s ease;
}

.profileIcon img:hover {
  background: #00dddd1a;
}

.adminProfile img {
  margin-left: 1em;
}

.profileIcon {
  cursor: pointer;
}

.profileDropdown {
  background: #fafafa;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 2px;
  visibility: hidden;
}

.profileDropdown p {
  margin-left: 10px;
  margin-top: 0.5em;
}
/*  */

/* Post Status Alert */
.postStatusAlert {
  opacity: 0;
  display: flex;
  background-color: #2aaada;
  animation-duration: 2s; /* Animate.css */
}

.postStatusAlertLeftColor {
  background-color: #1d90bb;
  padding: 0.5em;
}

.postStatusAlertText img {
  margin-right: 0.5em;
}

.postStatusAlertText {
  color: white;
  padding: 0.5em;
  font-size: 0.7em;
}

/* Admin Post */
.adminPostList {
  background-color: #fafafa;
  padding-left: 3em;
  padding-right: 3.4em;
  padding-bottom: 2.6em;
}

.adminPostListTitleContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 2.2em;
}

.adminPostListTitle {
  font-size: 2.2em;
  color: #0d4c86;
  font-weight: 700;
}

.adminPostListFunction {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adminPostListEachFunction {
  text-align: left;
  color: #0d4c86;
  font-size: 1em;
  margin-left: 1em;
}

.addPostButton button {
  background-color: #1f81dc;
  color: white;
  border-radius: 25px;
  border: transparent;
  padding: 0.8em 1em;
}

.adminDropdownButton {
  display: flex;
  align-items: center;
}

.adminDropdown button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  border: 1px solid #0d4c86;
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 25px;
  font-size: 0.8em;
  margin-right: 1em;
  width: 10em;
  text-align: left;
}

.adminDropdownError button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  border: 1px solid rgb(175, 15, 15);
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 25px;
  font-size: 0.8em;
  margin-right: 1em;
  width: 10em;
  text-align: left;
}

.adminDropdownIcon {
  margin-left: -25px;
}

.adminDropdown span {
  margin-right: 1em;
}

.adminDropdownError span {
  margin-right: 1em;
}

.ArticleButton {
  background-color: white;
  color: black;
  font-size: 0.8em;
  /* visibility: hidden; */
  position: absolute;
  text-align: left;
  border-radius: 1.5em;
  border: 1px solid #0d4c86;
  width: 10em;
  margin-top: 0.6em;
}

.ArticleChildrenList {
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 0.3em;
  cursor: pointer;
}

.ArticleChildrenList span {
  margin-right: 1em;
}

.ArticleChildrenList:hover {
  color: lightgray;
  font-weight: 700;
}

.adminPostTableContainer table {
  width: 95%;
  margin-left: 1.5em;
  background-color: #fafafa;
}

.adminPostTableContainer thead {
  border-bottom: 1px solid black;
}

.adminPostTableContainer th {
  padding: 0em 0em 0 1em;
}

.adminDeleteAll {
  min-width: 100px;
  font-weight: 400;
  transition: 0.3s ease;
  cursor: pointer;
}

.adminDeleteAll:hover {
  font-weight: 700;
}

.adminPostTableContainer td {
  padding: 1em 0em 1em 1em;
  max-width: 12.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tr.adminTableRow:hover {
  background: rgba(202, 220, 226, 0.46);
  font-weight: 700;
}

/* Custom checkbox */
.adminTableCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.adminTableCheck input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.adminTableCheckmark {
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.adminTableCheck:hover input ~ .adminTableCheckmark {
  background-color: #ccc;
}

.adminTableCheck input:checked ~ .adminTableCheckmark {
  background-color: black;
}

.adminTableCheckmark::after {
  content: "";
  position: absolute;
  display: none;
}

.adminTableCheck input:checked ~ .adminTableCheckmark::after {
  display: block;
}

.adminTableCheck .adminTableCheckmark::after {
  left: 6px;
  top: 3.5px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2.8px 2.8px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.adminTableCheckAll {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.adminTableCheckAll input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.adminTableCheckmarkAll {
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.adminTableCheckAll:hover input ~ .adminTableCheckmarkAll {
  background-color: #ccc;
}

.adminTableCheckAll input:checked ~ .adminTableCheckmarkAll {
  background-color: black;
}

.adminTableCheckmarkAll::after {
  content: "";
  position: absolute;
  display: none;
}

.adminTableCheckAll input:checked ~ .adminTableCheckmarkAll::after {
  display: block;
}

.adminTableCheckAll .adminTableCheckmarkAll::after {
  left: 5.3px;
  top: 9px;
  width: 9px;
  height: 7px;
  border: solid white;
  border-width: 2px 0 0 0;
}
/*  */

.adminPostInput {
  margin-bottom: 2em;
}

.adminPostInput input:focus {
  outline: none;
}

.adminPostInput input:not(:focus):not(:placeholder-shown):invalid {
  border-color: rgb(175, 15, 15);
}

.adminPostInput input:not(:focus):not(:placeholder-shown):valid {
  border-color: rgb(45, 157, 45);
}

.adminPostInput span {
  font-size: 0.7em;
  color: gray;
}

.adminImagePreview img {
  margin-top: 1em;
  max-width: 1000px;
  max-height: 500px;
}

.formErrorMessage p {
  font-size: 0.9em;
  color: rgb(175, 15, 15);
}

.resetImageButton {
  margin-top: 1em;
  padding: 0.5em 1em;
  background: #0d4c86;
  border: 1px solid #0d4c86;
  border-radius: 10em;
  font-size: 0.8em;
  color: white;
  transition: 0.3s ease;
}

.resetImageButton:hover {
  background: #ff9f1e;
  border: 1px solid #ff9f1e;
  color: black;
}

.adminPostButtonContainer {
  display: flex;
  justify-content: space-between;
}

.postCancelButton {
  background-color: transparent;
  color: #1f81dc;
}

/* Sweetalert */

button.swal2-cancel.cancelPostButton {
  border-radius: 10em;
  outline: none;
}

button.swal2-confirm.confirmPostButton {
  border-radius: 10em;
  outline: none;
  color: #1f81dc;
  border: 1px solid #1f81dc;
}

.swal2-title.cancelPostButtonTitle {
  font-size: 1.375em;
  font-weight: 400;
  color: #0d4c86;
}

.swal2-title.publishTitle {
  color: #11998e;
  font-weight: 700;
  font-size: 1.375em;
}

button.swal2-confirm.publishButton {
  border-radius: 10em;
}

div.swal2-popup.swal2-modal.publishPopup {
  border-radius: 1.5em;
}
/*  */

.postSaveButton {
  background-color: transparent;
  border-radius: 2em;
  border: 2px solid #1f81dc;
  font-size: 0.9em;
  padding: 0.5em 1.5em;
  color: #1f81dc;
  margin-left: 2em;
}

.postSaveButton:hover {
  background-color: #1f81dc;
  color: white;
}

.postFormButton {
  background-color: #1f81dc;
  border-radius: 2em;
  font-size: 0.9em;
  padding: 0.5em 1.5em;
  color: white;
  margin-left: 2em;
}

.postFormButton:hover {
  color: white;
}

/* Category Post */
.CategoryPostButton {
  background-color: #1f81dc;
  width: 18em;
  visibility: hidden;
  position: absolute;
  text-align: left;
  border-radius: 1em;
  margin-top: 1em;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  z-index: 1;
}

.CategoryPostList {
  margin: 1em;
  color: white;
  font-weight: 400;
}

.CategoryPostEachList {
  display: flex;
  justify-content: space-between;
}

.CategoryPostEachList span {
  font-size: 0.75em;
  color: #0d4c86;
  font-weight: 700;
}

.CategoryPostEachList input {
  width: 11em;
  background-color: transparent;
  border: transparent;
  color: white;
  -webkit-transition: 0.5s;
}

.CategoryPostEachList input:focus {
  border: 1px solid white;
  outline: none;
}

.CategoryPostEachList input::placeholder {
  color: white;
}

.submitCategory button {
  background-color: transparent;
  border: none;
}

/* Filter Button */
.overlayFilterButton {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 1;
  z-index: 1;
  overflow-y: auto;
}

.ButtonFilterTitle {
  display: flex;
  justify-content: space-between;
}

.ButtonFilterTitle span {
  margin-right: 1em;
  cursor: pointer;
}

span.resetFilterButton {
  color: #0c4477;
}

span.saveFilterButton {
  color: #f0f8ff;
}

.ButtonFilterTitle p {
  margin-left: 1em;
  color: #ff9f1e;
  font-weight: 700;
  margin-bottom: 0;
}

div.ButtonFilterList {
  margin-bottom: 0.5em;
}

.categoryAdminFilter {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 300px;
  width: auto;
}

.ButtonListContainer {
  width: 25em;
  overflow-y: auto;
}

.ButtonListContainer hr {
  color: #ffffff;
  margin: 3px;
}

.ButtonListFilter {
  background-color: #1f81dc;
  visibility: hidden;
  position: absolute;
  right: 175px;
  text-align: left;
  border-radius: 0.5em;
  margin-top: 1em;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  z-index: 1;
}

.ButtonFilterList input {
  background-color: #1f81dc;
}

.ButtonFilterList {
  margin-left: 1em;
  color: white;
}

.customMarginTop {
  margin-top: 1em;
}

.subSideBarContent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
}
