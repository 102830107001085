.eachLabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #0d4c86;
  margin-bottom: 0.5em;
}

.inputBox {
  background: #ffffff;
  border: 1px solid #0d4c86;
  border-radius: 40px;
  width: 400px;
  height: 50px;
  font-size: 15px;
  padding-left: 1em;
}

.adminPostInput {
  width: "100%";
  margin-bottom: 2em;
}

.typeOfWorkAndTagInput {
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eachContainer {
  width: 400px;
}

.inputImageContainer {
  width: "100%";
  height: 250px;
  background: #ffffff;
  border: 1px solid #0d4c86;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btnChangeImage {
  margin-left: 1.5em;
  width: 160px;
  height: 35px;
  background: #f0f8ff;
  border: 1px solid #000000;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.changeImageLabel {
  margin-left: 0.75em;
  color: #0d4c86;
  font-weight: 400;
  font-size: 14px;
}

.inputImageContainerNoPointer {
  width: "100%";
  height: 250px;
  background: #ffffff;
  border: 1px solid #0d4c86;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.imageToViewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageFileNameFontStyle {
  color: #798692;
  font-weight: 400;
  font-size: 13px;
}

.errorFailedToUpload p {
  color: #798692;
  font-weight: 400;
  font-size: 15px;
  color: rgb(175, 15, 15);
}

.labelImage {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-top: 1.5em;
  color: #000000;
}

.placeholderImage {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 1em;
  color: #798692;
}

.labelForDescription {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.secondLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: 1.5em;
  margin-top: -5px;
  color: #0d4c86;
}

.bigInputBox {
  width: 100%;
  height: 300px;
  background: #ffffff;
  border: 1px solid #0d4c86;
  border-radius: 20px;
  padding: 1em 1em 1em 1em;
  resize: none;
}

.normalInputBox {
  width: 100%;
  height: 150px;
  background: #ffffff;
  border: 1px solid #0d4c86;
  border-radius: 20px;
  padding: 1em 1em 1em 1em;
  resize: none;
}

.smallInputBox {
  width: 100%;
  height: 75px;
  background: #ffffff;
  border: 1px solid #0d4c86;
  border-radius: 20px;
  padding: 1em 1em 1em 1em;
  resize: none;
}

.xSmallInputBox {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 1px solid #0d4c86;
  border-radius: 20px;
  padding: 1em 1em 1em 1em;
  resize: none;
}

.workshopInputContainer {
  width: "100%";
  margin-bottom: 2em;
}

.labelForWorkshopInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
}

.btnAddMoreInput {
  width: 200px;
  height: 37px;
  background: #f0f8ff;
  border: 1px solid #000000;
  border-radius: 8px;
  color: #0d4c86;
  display: flex;
  font-size: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.eachFacilitatorLabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #0d4c86;
}

.errorMessage {
  margin-top: 0.5em;
  margin-left: 0.5em;
}

.errorMessage p {
  font-size: 0.9em;
  color: rgb(175, 15, 15);
}

.thLastModified {
  width: 16%;
}

.thName {
  width: 50%;
}

.tdLastModified {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.25em;
}

.thWorkshopTitle {
  width: 42.5%;
}

.tdEdit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tdEdit .editWorkshopBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #798692;
  cursor: pointer;
}

.tdEdit .deleteWorkshopBtn {
  margin-top: -4px;
  width: 20px;
  height: 22px;
  cursor: pointer;
}
