.academyDetailMainContainer {
  padding-top: 17vh;
}

.academyContent {
  margin: 1em;
  display: flex;
  justify-content: center;
  text-align: left;
  flex-wrap: wrap;
}

.academyTitle {
  color: #c55e24;
  /* font-size: 35px; */
  font-weight: 700;
  text-align: left;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  margin-left: 0.5em;
  margin-left: 0.5em;
}

.paragrafWorkshopSeries {
  margin: 1em;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}

.eachPropertyAcademyContainer {
  margin-bottom: 1.5em;
}

.eachPropertyAcademy {
  color: #104c84;
  /* font-size: 25px; */
  font-weight: 700;
  margin-bottom: 0.5em;
}

.eachPropertyAcademyDesc {
  margin-left: 0.5em;
}

.btnRegister {
  padding: 0.5em 1em 0.5em 1em;
  background-color: #ffc635;
  border-color: transparent;
}

.btnRegister img {
  width: 35px;
  height: 35px;
}

.btnWhatsapp {
  padding: 0.5em 1em 0.5em 1em;
  background-color: #d4ebff;
  border-color: transparent;
}

.btnWhatsapp img {
  width: 35px;
  height: 35px;
}

.allFacilitatorsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.eachFacilitators {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.eachFacilitatorsDescContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tableSchedule table {
  width: 100%;
  border: 1px solid #0d4c86;
}

.tableSchedule tr {
  border: none;
}

.tableSchedule td {
  border-bottom: 1px solid #0d4c86;
  border-top: 1px solid #0d4c86;
}

.thSchedule {
  width: 30%;
  color: #0d4c86;
  font-weight: 700;
  font-size: 18px;
}

.thDuration {
  width: 25%;
  color: #0d4c86;
  font-weight: 700;
  font-size: 18px;
}

.thLearningMethod {
  width: 25%;
  color: #0d4c86;
  font-weight: 700;
  font-size: 18px;
}

.thAction {
  width: 15%;
}

.tdScheduleValue {
  color: #000000;
  margin-left: 1em;
  font-weight: 700;
  font-size: 18px;
}

.adminTableRow td {
  font-size: 18px;
}

.facilitatorName {
  color: #c55e24;
  font-weight: 700;
}

.btnInvestmentFee {
  cursor: pointer;
  width: 191px;
  height: 35px;
  background-color: #1f81dc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.btnInvestmentFee div {
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  margin-left: 1.5em;
}

.thSchedule div {
  margin-left: 1em;
}

.tdDuration div {
  font-weight: 400;
  font-size: 18px;
}

.arrowUpDownIcon {
  width: 17px;
  height: 10px;
  margin-right: 1em;
}

.blogCardContainerWorkshop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.founderStoryExploreMorePicture {
  width: 350px;
  height: 300px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.founderStoryExploreMorePicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.founderStoryExploreMorePicture:hover {
  border-bottom: 5px solid #ff9f1e;
}

.founderStoryExploreMorePicture:hover img {
  transform: scale(1.5);
  object-fit: cover;
}

.testimonialWorkshopTitleFont {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}

.btnViewMore {
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
}

.btnViewMore div {
  width: 123px;
  height: 47px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  cursor: pointer;
}

.btnViewMore div {
  text-decoration: none;
  color: black;
  transition: 0.5s ease;
}

.btnViewMore div:hover {
  background-color: black;
  border-color: black;
  color: white;
  transition: 0.5s ease;
}

.blogCardEachWorkshop {
  margin: 30px;
  width: 550px;
  border-radius: 20px;
  margin-bottom: 100px;
}

.workshopCardPicture {
  width: 100%;
  height: 450px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  position: relative;
  width: 100%;
  margin: auto;
}

.workshopCardPicture:hover {
  border-bottom: 5px solid #ff9f1e;
}

.workshopCardPictureMainImage {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  max-width: 350px;
  aspect-ratio: 1080 / 1350;
  transform: translate(-50%, -50%);
}

.academyDetailImageContainer {
  position: relative;
  width: 100%;
  height: auto;
  margin: auto;
}

.academyDetailImageBackground {
  width: 100%;
  height: 750px;
}

.academyDetailMainImage {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  max-width: 600px;
  max-height: 750px;
  aspect-ratio: 1080 / 1350;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1100px) {
  .academyTitle {
    /* font-size: 25px; */
    margin-top: 2em;
  }

  .academyEmail {
    font-size: 15px;
  }

  .imgBtnInvestmentFee {
    width: 160px;
    height: 30px;
  }

  .thSchedule div {
    margin-left: 1em;
  }

  .tdScheduleValue {
    color: #000000;
    margin-left: 1em;
    font-weight: 700;
    font-size: 18px;
  }

  .thDuration {
    width: 27.5%;
    color: #0d4c86;
    font-weight: 700;
    font-size: 18px;
  }

  .thLearningMethod {
    width: 27.5%;
    color: #0d4c86;
    font-weight: 700;
    font-size: 18px;
  }

  .tdDuration div {
    font-weight: 400;
    font-size: 18px;
  }

  .blogCardContainerWorkshop {
    display: flex;
    justify-content: space-evenly;
    text-align: left;
    flex-wrap: wrap;
    padding: 0 2em;
  }

  .btnInvestmentFee {
    margin-right: 0.25em;
  }
}

@media only screen and (max-width: 900px) {
  .blogCardContainerWorkshop {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-wrap: wrap;
    /* padding: 0 2em; */
  }

  .btnInvestmentFee {
    margin-right: 0.25em;
  }

  .academyTitle {
    /* font-size: 25px; */
    margin-top: 2em;
  }

  .academyEmail {
    font-size: 15px;
  }

  .imgBtnInvestmentFee {
    width: 140px;
    height: 30px;
  }

  .thSchedule {
    width: 30%;
    color: #0d4c86;
    font-weight: 700;
    font-size: 17px;
  }

  .thSchedule div {
    margin-left: 0.5em;
  }

  .tdScheduleValue {
    color: #000000;
    font-weight: 700;
    font-size: 17px;
    margin-left: 0.5em;
  }

  .thDuration {
    width: 27.5%;
    color: #0d4c86;
    font-weight: 700;
    font-size: 17px;
  }

  .thLearningMethod {
    width: 27.5%;
    color: #0d4c86;
    font-weight: 700;
    font-size: 17px;
  }

  .tdDuration div {
    font-weight: 400;
    font-size: 17px;
  }
}

@media only screen and (max-width: 750px) {
}

@media only screen and (max-width: 600px) {
  .btnWhatsapp {
    padding: 0.5em 1em 0.5em 1em;
    font-size: 17px;
    background-color: #d4ebff;
    border-color: transparent;
  }

  .btnWhatsapp img {
    width: 25px;
    height: 25px;
  }

  .btnRegister {
    padding: 0.5em 1em 0.5em 1em;
    font-size: 17px;
    background-color: #ffc635;
    border-color: transparent;
  }

  .btnRegister img {
    width: 25px;
    height: 25px;
  }

  .thSchedule {
    width: 30%;
    color: #0d4c86;
    font-weight: 700;
    font-size: 15px;
  }

  .thSchedule div {
    margin-left: 0.5em;
  }

  .tdScheduleValue {
    color: #000000;
    margin-left: 0.5em;
    font-weight: 700;
    font-size: 15px;
  }

  .thDuration {
    width: 25%;
    color: #0d4c86;
    font-weight: 700;
    font-size: 15px;
  }

  .thLearningMethod {
    width: 27.5%;
    color: #0d4c86;
    font-weight: 700;
    font-size: 15px;
  }

  .tdDuration div {
    font-weight: 400;
    font-size: 15px;
  }

  .btnInvestmentFee {
    cursor: pointer;
    width: 115px;
    height: 35px;
    background-color: #1f81dc;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-right: 0.25em;
  }

  .btnInvestmentFee div {
    font-weight: 600;
    font-size: 9px;
    color: #ffffff;
    margin-left: 0.25em;
  }

  .arrowUpDownIcon {
    width: 10px;
    height: 5px;
    margin-right: 0.25em;
  }

  .blogCardContainerWorkshop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-left: 0;
    gap: 0;
    flex-wrap: nowrap;
    width: 100%;
  }

  .blogCardEachWorkshop {
    width: 100%;
    max-width: 100%;
    margin: 0 0 3.2em 0;
    margin-bottom: 100px;
  }
}
