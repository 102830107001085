/* Reusable Props */
.aboutUsTitle {
  color: #104c84;
  font-weight: 700;
  text-align: left;
}

.aboutUsTitleCenter {
  color: #104c84;
  font-weight: 700;
  text-align: center;
  margin-top: 94px;
}

.ourCurrentTeamMembersTitle {
  color: #104c84;
  font-weight: 700;
  text-align: center;
  margin-top: 50px;
  margin-left: 1em;
  margin-right: 1em;
}

.aboutUsTitle hr {
  border: 1px solid;
  color: #0d4c86;
}

.webTitle {
  font-weight: 700;
  margin: 115px 195px 55px 195px;
  color: #1f81dc;
}

/* The Founder - About/TheFounder */
#theFounder {
  padding-top: 10em;
  margin-left: 8.5em;
  margin-right: 8.5em;
  margin-bottom: 4.5em;
  padding-bottom: 6.5px;
}

/*  */
.eachMembersLinkedInText a {
  margin-left: 10px;
  text-decoration: none;
  color: black;
  display: inline-block;
}

.eachMembersLinkedInText a::after {
  content: "";
  width: 0px;
  height: 2px;
  display: block;
  background: black;
  transition: 300ms;
}

.eachMembersLinkedInText a:hover::after {
  width: 100%;
}
/*  */

.founderProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
}

.founderProfileTitle {
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 67px;
}

.founderProfileBio {
  display: flex;
}

.founderProfileBioPicture {
  max-width: 250px;
  max-height: 250px;
}

.founderProfileBioPicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.founderProfileBioText {
  margin-left: 51px;
  text-align: left;
}

.founderProfileBioTextQuote {
  font-size: 40px;
  color: #1f81dc;
  margin-bottom: 79px;
}

.founderProfileBioTextName {
  color: #0d4c86;
  font-weight: 700;
}

.founderProfileBioTextName p {
  margin-bottom: 0px;
}

.founderProfileBioTextLinkedIn {
  display: flex;
  align-items: flex-end;
  margin-top: 39px;
}

.founderProfileBioTextLinkedIn img {
  margin-bottom: 5px;
}

.founderProfileBioTextLinkedIn a {
  margin-right: 10px;
  text-decoration: none;
  color: black;
}

/* About - About/TheFounder */
.aboutFounder {
  display: flex;
}

.aboutFounderMainContainer {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.founderAbout {
  text-align: justify;
}

.aboutText {
  line-height: 40px;
}

/* Published Works - About/TheFounder */
.founderPublishedWorks {
  margin-top: 77px;
}

.publishedWorksList {
  text-align: left;
}

.publishedWorksList li {
  margin-bottom: 14px;
}

.publishedWorksList a {
  font-size: 14px;
}

/* Specialisation - About/TheFounder */
.aboutFounderSpecialisation {
  display: none;
  text-align: left;
  margin-top: 50px;
  margin-left: 83px;
  margin-right: 30px;
}

.specialisationTitle {
  font-size: 20px;
  font-weight: 700;
  padding-right: 150px;
  color: #0d4c86;
}

.specialisationList ul {
  padding-left: 0;
}

.specialisationList li {
  font-size: 15px;
  list-style-type: none;
  margin-bottom: 20px;
}

/* Company - About/Company */
#company {
  padding-top: 50px;
  padding-bottom: 100px;
}

.companyMainContainer {
  display: flex;
  flex-direction: column;
}

/* Company Banner - About/Company */

.companyBanner {
  position: relative;
}

.companyBanner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.companyBannerText {
  position: absolute;
  display: flex;
  text-align: left;
  max-width: 1440px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.companyBannerTitle {
  font-weight: 700;
  color: #ff9f1e;
  width: 100%;
  margin-left: 4vw;
}

.companyBannerSubtitle {
  width: 100%;
  margin-right: 4vw;
  font-weight: 700;
  color: white;
  line-height: 40px;
}

/* ISA Company - About/Company */
.aboutISAsubtext {
  margin: 32px 178px 106px 178px;
  line-height: 40px;
}

.ISAcompany {
  display: flex;
  justify-content: center;
  background-color: #fafafa;
}

.ISAcompanyMainContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 94px;
  margin-right: 3.6em;
  margin-bottom: 75px;
}

.ISAcompanyText {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 75%;
}

.ISAcompanyTextSub {
  line-height: 40px;
}

.ISAcompanyPicture {
  width: 100%;
  overflow: hidden;
}

.ISAcompanyPicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ISAcompanyButton button {
  margin-top: 30px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0px;
}

.ISAcompanyButton button:hover {
  border-radius: 0px;
  border-color: #104c84;
  background-color: #104c84;
  color: white;
}

.learnMoreContainer {
  display: flex;
  gap: 1vw;
  cursor: pointer;
  font-weight: 500;
}

/* Values - About/Company */

.valuesCompanySubtitle {
  color: #1f81dc;
  margin-bottom: 3em;
}

.valuesContent {
  margin: 0 9.375em 0 9.375em;
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-wrap: wrap;
}

.valuesEachContent {
  width: 30%;
  margin-top: 20px;
}

.valuesEachContent img {
  width: 30%;
}

.valuesEachContentTitle {
  margin-top: 20px;
  color: #0d4c86;
  font-weight: 700;
}

.valuesEachContentTitle p {
  margin-bottom: 0.4em;
}

/* Team Member - About/TeamMembers */
#teamMembers {
  padding-top: 50px;
}

.teamMembersContainer {
  display: flex;
  flex-direction: column;
}

.teamMembersBanner {
  position: relative;
  width: 100%;
}

.teamMembersBanner img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.teamMembersBannerText {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.teamMembersBannerTitle {
  font-weight: 700;
  color: #ff9f1e;
  width: 100%;
}

.allMembersContainer {
  margin: 100px 50px 100px 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;
}

.allTestimonialsContainer {
  margin: 100px 50px 100px 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;
}

.testimonialMembersContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.eachMembers {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 35px;
}

.eachMembersPicture {
  max-width: 300px;
  max-height: 300px;
  overflow: hidden;
  cursor: pointer;
}

.eachMembersPicture img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.eachMembersName p {
  margin-top: 25px;
  margin-bottom: 0;
  font-weight: 700;
  color: #0d4c86;
  cursor: pointer;
  text-align: center;
}

.internName p {
  margin-bottom: 0;
  font-weight: 700;
  color: #0d4c86;
  cursor: pointer;
}

.eachMembersTitle p {
  margin-top: 5px;
  margin-bottom: 0;
}

.eachMembersLinkedIn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.eachMembersLinkedIn img {
  margin-bottom: 5px;
}

.eachMembersLinkedInText a {
  margin-left: 10px;
  text-decoration: none;
  color: black;
  display: inline-block;
}

.eachMembersLinkedInText a::after {
  content: "";
  width: 0px;
  height: 2px;
  display: block;
  background: black;
  transition: 300ms;
}

.eachMembersLinkedInText a:hover::after {
  width: 100%;
}

.ourInternTestimonial {
  color: #104c84;
  font-weight: 700;
  text-align: center;
}

.containerDesc {
  margin-left: 20px;
  max-width: 750px;
}

.eachMembersDescContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.eachTestimonialDescContainer {
  display: flex;
}

.bannerContentContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 3em;
  background-color: transparent;
}

.bannerImageContainer img {
  width: 350px;
  height: auto;
  filter: brightness(100%);
}

.bannerDescContainer {
  width: 30%;
  font-weight: 700;
  color: white;
  line-height: 40px;
} 

@media only screen and (max-width: 1400px) {
  .containerDesc {
    margin-left: 20px;
    max-width: 850px;
  }
}

@media only screen and (max-width: 1300px) {
  .containerDesc {
    margin-left: 20px;
    max-width: 750px;
  }
}

@media only screen and (max-width: 1200px) {
  .containerDesc {
    margin-left: 20px;
    max-width: 650px;
  }
}

@media only screen and (max-width: 1100px) {
  .containerDesc {
    margin-left: 20px;
    max-width: 575px;
  }
}

@media only screen and (max-width: 1050px) {
  .containerDesc {
    margin-left: 20px;
    max-width: 450px;
  }
}

@media only screen and (max-width: 950px) {
  .containerDesc {
    margin-left: 20px;
    max-width: 425px;
  }
}

@media only screen and (max-width: 900px) {
  /* Reusable Props */

  .eachMembersDescContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .containerDesc {
    margin-top: 1.5em;
    margin-left: 0px;
    max-width: 400px;
  }

  .aboutUsTitleCenter {
    margin: 1em;
    margin-top: 2em;
  }

  .webTitle {
    margin: 1em;
  }

  /* Company */
  .companyMainContainer,
  .teamMembersMainContainer {
    padding-top: 3em;
  }

  .companyBanner,
  .teamMembersBanner {
    overflow: hidden;
  }

  .companyBanner img,
  .teamMembersBanner img {
    width: 100%;
  }

  .companyBannerTitle {
    color: white;
    width: 100%;
    margin-left: 1em;
  }

  .companyBannerSubtitle {
    display: none;
  }

  .aboutISAsubtext {
    margin: 1.875em 2.188em 1.875em 2.188em;
  }

  .ISAcompany {
    flex-direction: column-reverse;
  }

  .ISAcompanyText {
    max-width: 100%;
    margin-left: 2.188em;
  }

  .valuesContent {
    margin: 3em;
  }

  /* Team Members */
  .allMembersContainer {
    margin-top: 2.5em;
  }

  .teamMembersBannerTitle {
    color: #ff9f1e;
  }

  .eachMembers {
    max-width: 300px;
  }

  .eachMembersPicture {
    max-height: 250px;
  }

  /* Team Members - Detail */
  #theFounder {
    margin: 3em;
  }

  .aboutFounderMainContainer {
    margin-right: 0;
  }

  .testimonialMembersContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bannerContentContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 3em;
    background-color: transparent;
  }
  
  .bannerImageContainer img {
    width: 300px;
    height: auto;
    filter: brightness(100%);
  }
  
  .bannerDescContainer {
    width: 60%;
    font-weight: 700;
    color: white;
    line-height: 40px;
  } 
}

@media only screen and (max-width: 500px) {
  /* Company */

  .companyBanner img {
    width: 150%;
  }

  .valuesCompanySubtitle {
    /* font-size: 1.125em; */
    margin: 1em;
  }

  .valuesContent {
    margin: 0 2.188em 0 2.188em;
  }

  .valuesEachContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  /* Team Members */

  .allMembersContainer {
    margin-top: 2.5em;
  }

  .teamMembersBannerTitle {
    color: #ff9f1e;
  }

  /* Team Members - Detail */

  #theFounder {
    margin: 2em;
  }

  .founderProfileBio {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .founderProfileBioPicture {
    max-width: 175px;
    max-height: 175px;
  }

  .founderProfileBioPicture img {
    width: 175px;
    height: 175px;
  }

  .founderProfileBioText {
    margin: 0;
  }

  .founderProfileBioTextName {
    margin-top: 1em;
  }

  .founderProfileBioTextTitle {
    margin-top: 1em;
  }

  .founderProfileBioTextLinkedIn {
    margin-top: 0;
  }

  .aboutFounderMainContainer {
    margin-right: 0;
  }

  .containerDesc {
    margin-left: 0px;
  }

  .eachMembersDescContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .testimonialMembersContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .eachTestimonialDescContainer {
    display: flex;
    flex-direction: column;
  }

  .eachMembers {
    max-width: 300px;
    margin: 25px;
  }

  .bannerContentContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 3em;
    background-color: transparent;
  }
  
  .bannerImageContainer img {
    width: 200px;
    height: auto;
    filter: brightness(100%);
  }
  
  .bannerDescContainer {
    width: 60%;
    font-weight: 700;
    color: white;
    line-height: 40px;
  } 
}
