#loginPage, #resetPassword, #registerPage, #sendEmailPage {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #C4C4C4;
    overflow-y: auto;
}

.loginPageContainer {
    margin-top: 6em; 
}

.loginFormContainer {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
}

.loginFormImage {
    max-width: 100px;
}

.loginFormImage img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.loginFormTitle p {
    font-size: 2.2em;
    color: #0D4C86;
    margin-bottom: 0;
}

.loginFormSubTitle p {
    color:#fb9209;
    font-weight: 600;
}

.loginForm {
    text-align: left;
}

.loginForm button[type="submit"]:disabled {
    background-color: #C4C4C4;
    color: white;
}

.loginForm button[type="submit"] {
    background-color: #1F81DC;
    color: white;
}

/* Login Input */
.loginFormInput, .loginForm button {
    width: 24em;
    margin-top: 0.8em;
    margin-bottom: 1em;
}

.loginFormInput input {
    border: 1px solid black;
    border-radius: 8px;
}

.loginFormInput label {
    margin-bottom: 0.4em;
}

.loginFormInput img {
    width: 0.8em;
    margin-right: 0.5em;
}

.loginFormInput p {
    font-size: 0.7em;
    color: red;
    float: right;
}
/*  */

.loginCaptcha {
    margin-bottom: 1em;
}

.loginForm button {
    background-color: #C4C4C4;
    border-radius: 25px;
    color: white;
}

.loginForgotPassword p {
    color: #0D4C86;
    font-size: 0.8em;
    cursor: pointer;
    margin-top: 0.8em;
}

.loginEyePassword {
    position: relative;
    float: right;
    margin-top: -2em;
    cursor: pointer;
}

.loginFormMinitext {
    margin-top: 1em;
    font-size: 0.7em;
    text-align: center;
    color: #798692;
}
/* Register Input */

.registerFormInput, .loginForm button {
    width: 24em;
    margin-top: 0.8em;
    margin-bottom: 1em;
}

.registerFormInput input {
    border: 1px solid black;
    border-radius: 8px;
}

.registerFormInput input:not(:focus):not(:placeholder-shown):valid {
    border-color: #1F81DC;
    color: #1F81DC;
}

.registerFormInput input:not(:focus):not(:placeholder-shown):invalid {
    border-color: rgb(175, 15, 15);
}

.registerFormInput label {
    margin-bottom: 0.4em;
}

.registerFormInput img {
    width: 0.8em;
    margin-right: 0.5em;
}

.registerFormInput p {
    font-size: 0.7em;
    color: red;
    float: right;
}
/*  */
.authenticationPageNavigation p {
    text-align: center;
    font-size: 0.8em;
    color: #0D4C86;
}

.authenticationPageNavigation span {
    cursor: pointer;
    font-weight: 700;
}

