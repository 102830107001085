/* Hide reCAPTCHA */
.grecaptcha-badge {
  visibility: hidden;
}

/*  */

.contactContainer {
  padding-top: 10em;
  display: flex;
  justify-content: space-around;
}

.contactBannerContainer {
  text-align: left;
  width: 30%;
  padding-bottom: 20px;
}

.contactBannerTitle {
  font-weight: 700;
}

.contactBannerSubTitle {
  margin-top: 67px;
  margin-bottom: 87px;
  color: #1f81dc;
}

.contactMainTitle {
  color: #0d4c86;
  font-weight: 700;
}

.contactHeadOfficeEmail span {
  font-weight: 700;
}

.contactWhatsApp {
  margin-top: 84px;
}

.contactWhatsAppButton a {
  color: white;
  text-decoration: none;
  background-color: #2aaada;
  padding: 10px;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 25px;
}

.contactWhatsAppButton a:hover {
  color: white;
  background-color: #0d4c86;
}

.contactWhatsAppButton a:hover img {
  filter: invert(75%) sepia(25%) saturate(7082%) hue-rotate(162deg)
    brightness(150%) contrast(89%);
}

.contactFormContainer {
  margin-top: 270px;
  text-align: left;
  width: 50%;
  margin-bottom: 200px;
}

.contactFormSection {
  margin-top: 30px;
}

.contactFormSection label {
  font-weight: 600;
  margin-bottom: 10px;
}

.contactFormSection input {
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.contactFormSection textarea {
  border: 1px solid black;
  width: 100%;
}

.contactFormSection p {
  padding-top: 5px;
  color: red;
}

.contactFormCheckbox {
  margin-top: 37px;
  margin-bottom: 37px;
}

.contactFormCheckbox input {
  margin-right: 10px;
  margin-top: 10px;
  transform: scale(1.2);
}

.contactFormSubmit {
  margin-top: 20px;
}

.contactFormSubmit button {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 0px;
}

.contactFormSubmit button:hover {
  border-color: #104c84;
  background-color: #104c84;
  color: white;
}

.contactFormSubmit button:hover img {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  transition: 0.3s ease;
}

@media only screen and (max-width: 820px) {
  .contactContainer {
    padding-top: 7em;
    margin: 2em;
    flex-direction: column;
  }

  .contactBannerSubTitle {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .contactBannerContainer,
  .contactFormContainer,
  .contactFormSection input,
  .contactFormSection textarea {
    width: 100%;
    /* max-width: 80%; */
  }

  .contactFormContainer {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .contactWhatsApp {
    margin-top: 3em;
  }

  .contactWhatsAppButton a {
    padding-left: 2em;
    padding-right: 2em;
  }
}

@media only screen and (max-width: 500px) {
}
