.detailBlogContainer {
  padding-top: 200px;
  text-align: left;
  margin: 0 136px 0 136px;
}

.detailBlogMainTitle {
  font-weight: 700;
  color: #0d4c86;
}

.detailBlogHtmlContent {
  word-wrap: break-word;
}

.detailBlogHtmlContent p,
.detailBlogHtmlContent span,
.detailBlogHtmlContent li {
  font-size: 20px !important;
}

.detailBlogHtmlContent img {
  max-width: 100%;
  object-fit: contain;
}

.detailBlogSocialMedia {
  display: flex;
  margin-bottom: 82px;
  margin-top: 1em;
}

.right {
  justify-content: flex-end;
}

.left {
  justify-content: flex-start;
}

.detailUpdates {
  background-color: #fafafa;
}

.detailUpdatesContainer {
  padding-top: 50px;
}

.detailUpdatesMainTitle {
  color: #1f81dc;
}

.detailUpdatesCardContainer {
  text-align: left;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
}

.detailUpdatesCardEach {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin: 20px;
}

.detailUpdatesCardTitle {
  margin-top: 30px;
  margin-bottom: 15px;
  cursor: pointer;
}

.detailUpdatesCardTitle p {
  font-weight: 700;
  color: #0d4c86;
  text-decoration: none;
  display: inline-block;
}

.detailUpdatesCardTitle p:hover {
  color: #ff9f1e;
}

.detailUpdatesCardTitle p::after {
  content: "";
  width: 0px;
  height: 2px;
  display: block;
  background: #ff9f1e;
  transition: 300ms;
}

.detailUpdatesCardTitle p:hover::after {
  width: 100%;
}

.detailUpdatesCardImage {
  width: 330px;
}

.detailUpdatesCardImage img {
  width: 100%;
  height: 330px;
  object-fit: cover;
}

@media only screen and (max-width: 500px) {
  .detailBlogContainer {
    padding-top: 200px;
    text-align: left;
    margin: 0 50px 0 50px;
  }

  .detailBlogSocialMedia {
    margin-bottom: 3em;
  }

  .detailBlogHtmlContent p {
    font-size: 14px;
  }

  .detailBlogHtmlContent img {
    max-width: 300px;
  }

  .detailUpdatesCardContainer {
    margin-left: 1em;
    margin-right: 1em;
  }

  .right {
    justify-content: flex-start;
  }
}
