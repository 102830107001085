body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.header {
  font-size: 55px;
}

.headerDescription {
  font-size: 40px;
}

.title {
  font-size: 30px;
}

.paragraf {
  font-size: 22px;
}

.paragrafDesc {
  font-size: 18px;
}

.text {
  font-size: 16px;
}

.littleText {
  font-size: 12px;
}

@media only screen and (max-width: 900px) {
  .header {
    font-size: 35px;
  }

  .headerDescription {
    font-size: 30px;
  }

  .title {
    font-size: 25px;
  }

  .paragraf {
    font-size: 18px;
  }

  .paragrafDesc {
    font-size: 16px;
  }

  .text {
    font-size: 14px;
  }
}
