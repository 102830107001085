.ql-editor {
    height: 250px;
}

.sun-editor .se-btn-primary {
    background-color: #0D4C86;
    border: none;
}

.sun-editor .se-btn-primary:hover {
    background-color: #0D4C86;
}


.se-btn-primary span {
    color: white;
}