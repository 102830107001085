.activityPublicContainer {
  padding-top: 6em;
}
.activityPublicListContainer {
  padding-top: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activityPublicCardContainer {
  text-align: left;
  padding-bottom: 60px;
}

.activityPublicCardListContainer {
  padding-bottom: 60px;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 96%;
}

.activityTitle {
  padding-top: 70px;
  font-weight: 700;
  color: #c55e24;
  text-align: left;
  width: 100%;
  margin-left: 1em;
}

.activityPublicCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.activityPublicListCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.activityPublicCardPicture {
  width: 100%;
  height: 400px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.activityPublicCardPicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.founderStoryPublicCardPicture {
  width: 100%;
  height: 600px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.founderStoryPublicCardPicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.activityPublicCardTitle {
  margin: 29px 0px 10px 0px;
}

.activityPublicCardTitle p {
  font-weight: 700;
  color: #c55e24;
  text-decoration: none;
  display: inline-block;
}

.activityPublicCardTitle p:hover {
  color: #ff9f1e;
  cursor: pointer;
}

.activityPublicCardTitle p::after {
  content: "";
  width: 0px;
  height: 2px;
  display: block;
  background: #ff9f1e;
  transition: 300ms;
}

.activityPublicCardTitle p:hover::after {
  width: 100%;
}

.activityPublicCardType {
  font-weight: 600;
}

.activityPublicCardDescription {
  font-weight: 400;
}

.activityPublicDetailCardContainer {
  text-align: left;
}

.activityPublicDetailCardContainerDesc {
  padding-left: 4vw;
  padding-right: 4vw;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.activityPublicEachCard {
  margin: 30px;
  width: 550px;
  border-radius: 20px;
}

.activityPublicListEachCard {
  margin: 30px;
  width: 440px;
  border-radius: 20px;
}

.image-viewer {
  display: inline-block;
}

.image-container {
  position: relative;
  cursor: pointer;
}

.image-viewer.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-viewer.full-screen .image-container {
  width: 80%;
  max-height: 80%;
}

.image-viewer.full-screen img {
  max-width: 100%;
  max-height: 100%;
}

/* Mobile Resolution */
@media only screen and (max-width: 852px) {
  .activityPublicCardPicture {
    width: 100%;
    height: 325px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
  }

  .activityPublicEachCard {
    margin: 30px;
    width: 100vw;
    border-radius: 20px;
  }

  .activityPublicContainer {
    padding-top: 5em;
  }

  .activityTitle {
    padding-top: 70px;
    font-weight: 700;
    margin: 0;
    text-align: center;
    color: #c55e24;
  }

  .activityPublicCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .activityPublicListCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
