.componentSocialMedia {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 350px;
}

.componentSocialMedia a {
    margin: 10px;
    border: 1px solid black;
    border-radius: 100%;
    padding: 10px;
    transition: .5s ease;
}

.componentSocialMedia img {
    width: 100%;
    max-width: 24px;
    height: auto;
}

.componentSocialMedia a:hover {
    background-color: black;
    transition: .5s ease;
}

.componentSocialMedia a:hover img {
    filter: invert(100%);
    -webkit-filter: invert(100%);
    transition: .5s ease;
}

@media only screen and (max-width: 820px) {

    .componentSocialMedia a {
        margin: 5px;
    }
}