#privacyStatement {
    padding-top: 13em;
}

.privacyStatementContainer {
    margin: 8.5em;
    margin-top: 0;
    text-align: left;
}

.privacyPageTitle {
    font-size: 3.5em;
    font-weight: 700;
    margin-bottom: 1.5em;
}

.privacyDate {
    font-size: 1.1em;
    margin-bottom: 1.5em;
}

.privacyMainTitle {
    font-size: 2.5em;
    margin-bottom: 1em;
}

.privacySubTitle {
    font-size: 1.1em;
    font-weight: 700;
    margin-bottom: 1em;
}

.privacyParagraph {
    font-size: 1.1em;
    line-height: 2.5em;
    margin-bottom: 4em;
}

.privacyParagraph ul {
    list-style-type: none;
}

.privacyParagraph ul li:before {
    content: '\2014';
    position: absolute;
    margin-left: -30px;
}

@media only screen and (max-width: 500px) {
    .privacyStatementContainer {
        margin: 3em;
    }

    .privacyPageTitle {
        font-size: 30px;
    }

    .privacyMainTitle {
        font-size: 24px;
    }
}