.previousButton {
    cursor: pointer;
    border-radius: 25px;
    margin-bottom: 30px;
}

.previousButton a {
    color: #1F81DC;
    text-decoration: none;
    display: inline-block;
}

.previousButton a::after {
    content: '';
    width: 0px;
    height: 2px;
    display: block;
    background: black;
    transition: 300ms;
}

.previousButton a:hover {
    color: black;
}

.previousButton:hover .fa-angle-left {
    color: black;
}

.previousButton a:hover::after {
    width: 100%;
}

.fa-angle-left {
    color: #1F81DC;
}

.previousButton p {
    margin-bottom: 0px;
}