.eachfounderStoryDescMainContainer {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 35px;
}

.eachFounderStoryDescContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.founderStoryCardTitle a {
  /* font-size: 35px; */
  font-weight: 700;
  color: #0d4c86;
  text-decoration: none;
  display: inline-block;
  text-align: left;
}

.founderStoryCardTitle a:hover {
  color: #0d4c86;
}

.founderStoryCardTitle a::after {
  content: "";
  width: 0px;
  height: 2px;
  display: block;
  background: #0d4c86;
  transition: 300ms;
}

.founderStoryCardTitle a:hover::after {
  width: 100%;
}

.founderStoryContainerDesc {
  margin-left: 20px;
  max-width: 750px;
}

@media only screen and (max-width: 650px) {
  .eachfounderStoryDescMainContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 35px;
    margin-left: 9vw;
    margin-right: 9vw;
  }

  .eachFounderStoryDescContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    background-color: red;
    width: 100vw;
  }

  .founderStoryContainerDesc {
    margin-left: 20px;
    max-width: 750px;
  }
}
