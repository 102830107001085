/* Collapse bootstrap */
.accordion-item {
  border: transparent;
  outline: none !important;
  box-shadow: none;
}

.expertiseDownloadButton {
  margin-top: 1em;
}

.expertiseDownloadButton a {
  text-decoration: none;
  color: #0d4c86;
}

.expertiseDownloadButton img {
  width: 1.2em;
}

button.accordion-button {
  color: #0d4c86;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  padding-bottom: 0px;
  padding-left: 0px;
  align-items: flex-start;
}

button.accordion-button.collapsed {
  outline: none !important;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #0d4c86;
  background-color: transparent;
  outline: none !important;
  box-shadow: none;
}

.accordion-button:not(.collapsed) .fa-angle-right {
  transform: rotate(90deg);
  transition: 0.5s ease;
}

.accordion-button::after {
  display: none;
}

.accordion-body {
  line-height: 2.2em;
}
/*  */

#expertise {
  padding-top: 50px;
}

.expertiseMainBannerImageContainer {
  overflow: hidden;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 6.25em;
}

.expertiseMainBanner img {
  object-fit: cover;
}

.expertiseMainBannerText {
  position: absolute;
  color: white;
}

.expertiseMainBannerText p {
  max-width: 620px;
  position: fixed;
  line-height: 80px;
  font-weight: 700;
  top: 23vw;
  left: 50%;
  transform: translate(-50%, -50%);
}

.expertiseSubContainer {
  text-align: left;
  /* margin-left: 136px;
    margin-right: 136px; */
  padding-bottom: 1em;
  padding-top: 94px;
}

.expertiseSubTitle {
  font-weight: 600;
}

.afterFirstSubTitle {
  margin-top: 82px;
}

.expertiseChildrenTitle {
  line-height: 1.8em;
}

.expertiseContentTitle {
  color: #0d4c86;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

.expertiseArrowRight {
  margin-right: 10px;
  color: black;
}

.fa-angle-right {
  transition: 0.5s ease;
}

.expertiseContentSub {
  font-size: 18px;
  font-weight: 400;
  color: black;
  line-height: 40px;
}

.expertiseParallax {
  margin-top: 6.25em;
  margin-bottom: 6.25em;
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background1 {
  background-image: url(https://media.discordapp.net/attachments/796711355876245534/942666496340029461/CloudxierISAexpertise1.png);
}

.background2 {
  background-image: url(https://cdn.discordapp.com/attachments/796711355876245534/961473310254854144/97139973_s.jpg);
}

.expertiseBanner {
  background-color: #f2f2f2;
  margin-top: 75px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.expertiseBannerContainer {
  display: flex;
  /* justify-content: center; */
}

.expertiseBannerContent {
  margin-left: 137px;
  text-align: left;
  width: 50%;
}

.expertiseBannerTitle {
  margin-bottom: 50px;
  font-size: 55px;
  font-weight: 700;
  color: #1f81dc;
}

.expertiseBannerSubTitle {
  font-size: 35px;
  color: #0d4c86;
}

.expertiseBannerLink a {
  font-size: 15px;
  text-decoration: none;
  color: black;
}

.expertiseBannerLink img {
  width: 3%;
  margin-right: 10px;
}

.expertiseBannerImage {
  max-width: 1000px;
  max-height: 500px;
}

.expertiseBannerImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Expertise Form */

.popupExpertiseForm form {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 0.7em;
}

.popupExpertiseForm input,
select {
  border: 1px solid black;
  border-radius: 0.7em;
  margin-bottom: 1em;
  padding-left: 0.7em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.expertiseFormInput {
  display: flex;
  flex-direction: column;
}

.expertiseFormInput label {
  margin-bottom: 0.3em;
}

.formSelectInput select {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-position: calc(100% - 12px) center !important;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  padding: 8px 32px 8px 16px;
}

.formSelectInput select::-ms-expand {
  display: none;
}

.expertiseFormInput input:not(:focus):not(:placeholder-shown):valid {
  border-color: rgb(45, 157, 45);
  font-weight: 700;
}

.popupExpertiseForm select:not(:focus):not(:placeholder-shown):valid {
  border-color: rgb(45, 157, 45);
  font-weight: 700;
}

.popupExpertiseForm select:not(:focus):not(:placeholder-shown):invalid {
  border-color: rgb(175, 15, 15);
}

.expertiseFormInput input:not(:focus):not(:placeholder-shown):invalid {
  border-color: rgb(175, 15, 15);
}

.popupExpertiseForm p {
  /* font-size: 1em !important; */
  margin-top: -1em;
  font-weight: 400 !important;
  color: red !important;
}

.popupExpertiseFormSubmit {
  display: flex;
  justify-content: center;
}

.popupExpertiseForm input[type="submit"] {
  border-radius: 0px;
  /* font-size: 1em; */
  transition: 0.5s ease;
  width: 50%;
  margin: 1em;
}

.popupExpertiseForm input[type="submit"]:hover {
  color: white;
  border: 1px solid #0d4c86;
  background-color: #0d4c86;
  transition: 0.5s ease;
}

/* Override the default country flag size */
.react-phone-number-input .flag {
  width: 30px;
  height: 20px;
}

/* Change the flag border radius */
.react-phone-number-input .flag-select .selected-flag {
  border-radius: 50%;
}

/* Change the flag background color or apply any other styles */
.react-phone-number-input .selected-flag {
  background-color: #f0f0f0; 
}

@media only screen and (max-width: 820px) {
  .expertiseMainBanner {
    display: none;
  }

  .experimeMainBannerSmallScreen {
    padding-top: 3em;
    margin-left: 136px;
    margin-right: 136px;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    text-align: left;
    color: #1f81dc;
  }

  .expertiseParallax {
    background-size: 1600px, cover;
    min-height: 200px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .experimeMainBannerSmallScreen {
    margin-left: 43px;
    margin-right: 43px;
  }

  .expertiseSubContainer {
    margin-left: 43px;
    margin-right: 43px;
  }

  button.accordion-button {
    font-size: 18px;
  }

  .expertiseParallax {
    background-size: 500px, cover;
  }

  .popupExpertiseForm input[type="submit"] {
    width: 100%;
  }
}
